<template>
  <v-card>
    <v-toolbar dark color="blue" class="darken-1">
      <v-toolbar-title>
        {{ $t("edit_employee_form") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="dialogueClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-card-text class="px-5 ">
      <v-container class="px-5 ">
        <form class="px-5 fix-for-nepali-label">
          <v-text-field
            v-model="editedData.empName"
            :error-messages="empNameErrors"
            required
                        dense
                        outlined
            @input="$v.editedData.empName.$touch()"
            @blur="$v.editedData.empName.$touch()"
          >
            <template v-slot:label>
              {{ $t("name") }}
            </template>
          </v-text-field>
          <v-text-field
            v-model="editedData.empAddress"
            :error-messages="empAddressErrors"
            required
                        dense
                        outlined
            @input="$v.editedData.empAddress.$touch()"
            @blur="$v.editedData.empAddress.$touch()"
          >
            <template v-slot:label>
              {{ $t("address") }}
            </template>
          </v-text-field>
          <v-text-field v-model="editedData.empEmail"
                        dense
                        outlined>
            <template v-slot:label>
              {{ $t("email") }}
            </template>
          </v-text-field>
          <v-text-field
            v-model="editedData.empPhone"
            :error-messages="empPhoneErrors"
            required
                        dense
                        outlined
            @input="$v.editedData.empPhone.$touch()"
            @blur="$v.editedData.empPhone.$touch()"
          >
            <template v-slot:label>
              {{ $t("phone") }}
            </template>
          </v-text-field>
          <v-autocomplete
            v-model="editedData.empType"
            :error-messages="empTypeErrors"
            required
                          dense
                        outlined
            @input="$v.editedData.empType.$touch()"
            @blur="$v.editedData.empType.$touch()"
            label="Employee Types"
            clearable
            :items="EmployeeTypes"
            item-text="value"
            item-value="id"
          >
            <template v-slot:label>
              {{ $t("employee_type") }}
            </template>
          </v-autocomplete>
        </form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn dark color="rgb(157,25,25)" @click="dialogueClose">{{
        $t("cancel")
      }}</v-btn>
      <v-btn dark color="blue darken-1" @click="submit">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "EmployeeForm",
  props: ["hotelEmployeeID"],
  computed: {
    empNameErrors() {
      const errors = [];
      if (!this.$v.editedData.empName.$dirty) return errors;
      !this.$v.editedData.empName.required &&
        errors.push(this.$t("employee_name") + " " + this.$t("is_required"));
      return errors;
    },
    empAddressErrors() {
      const errors = [];
      if (!this.$v.editedData.empAddress.$dirty) return errors;
      !this.$v.editedData.empAddress.required &&
        errors.push(this.$t("address") + " " + this.$t("is_required"));
      return errors;
    },
    empPhoneErrors() {
      const errors = [];
      if (!this.$v.editedData.empPhone.$dirty) return errors;
      !this.$v.editedData.empPhone.required &&
        errors.push(this.$t("phone") + " " + this.$t("is_required"));
      return errors;
    },
    empTypeErrors() {
      const errors = [];
      if (!this.$v.editedData.empType.$dirty) return errors;
      !this.$v.editedData.empType.required &&
        errors.push(this.$t("employee_type") + " " + this.$t("is_required"));

      return errors;
    },
  },
  watch: {
    updated: {
      handler: function(val) {
        if (this.updated) {
          this.updated = false;
          this.$emit("formResponse", val);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      province: [],
      show: false,
      updated: false,
      editedData: {
        empName: "",
        empEmail: "",
        empPhone: "",
        empAddress: "",
        empType: "",
      },
      servicesData: {
        data: {},
        message: "Cancelled",
        color: "red",
      },
      EmployeeTypes: [
        { id: "Permanent", value: "Permanent" },
        { id: "Temporary", value: "Temporary" },
      ],
    };
  },
  validations: {
    editedData: {
      empName: { required },
      empAddress: { required },
      empPhone: { required },
      empType: { required },
    },
  },
  destroyed() {},
  mounted() {
    this.formData();
  },
  methods: {
    dialogueClose() {
      this.$emit("formResponse", this.servicesData);
    },
    async formData() {
      const { data } = await axios.get(
        "Employee/GetEmployeeDetailByIDAsync/" + this.hotelEmployeeID
      );
      this.editedData = data;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        axios
          .post("Employee/UpdateEmployeeDetailAsync", this.editedData)
          .then((response) => {
            if (response.data.success) {
              this.$v.$reset();
              this.editedData = {};
              this.servicesData.data = response.data;
              this.servicesData.message = "Employee updated Successfully";
              this.servicesData.color = "success";
              this.dialogueClose();
            }
          })
          .catch((response) => {
            this.servicesData.data = response.data;
            this.servicesData.message =
              "Error Updating Employee, Please Contact Admin";
            this.servicesData.color = "error";
          });
      }
    },
    clear() {
      this.$v.$reset();
      this.editedData.empName = "";
      this.editedData.empAddress = "";
      this.editedData.empPhone = "";
      this.editedData.empType = "";
    },
  },
};
</script>

<style scoped></style>
